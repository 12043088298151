import './Home.css'
import ParagraphPanel from '../components/ParagraphPanel';
import MainCarousel from '../components/MainCarousel';
import React from 'react';

export default function Home(){
  return (
    <div className="App">
      <ParagraphPanel
       
        titlePanel="Full-Stack Software Developer"
        textPanel="Most Recently: Build Engineer, Unity Programmer"
        borderVariant="default"
      />
      <div className="MainContent">
        <img 
          src="images/Profile_Base.jpg" 
          className="ProfilePic"
          alt="Profile Picture"
        />
        <div>
          <h1 className=" AboutMeTitle">Hello, I'm Irene Mayor!</h1>
          <p style={{textAlign: "justify", color:"#003049"}}>
              I'm a Unreal Build Engineer, Unity Programmer, Full-Stack Developer located in Vancouver, Canada! I love working on creative projects involving storytelling or digital media and <b>creating the technical foundations behind the scenes</b> that provides <b>ease of use for users</b> to facilitate the process. <i>Technology should adapt to people, not the other way around</i> is one of my driving mottos. 
              <br></br>
              <br></br>
              If you have a collaborative team needing a <b>programmer who's adaptable, communicative and eager to solve/optimize with others</b> then let's connect!
              <br/>
              <br/>
              I have a total of eight years programming experience, two of which have been in Games, as I love adapting to whatever opportunies are presented to me and dive headfirst into a diverse range of technologies. Give me a few weeks, and I'll start figuring things out! I do have four years Unity experience as I have used Unity in non-game software projects.
              <br/>
              <br/>
              Most recently, I worked at <b>Timbre Games Studio</b> where I worked as a <b>Build Engineer</b> working on CI/CD pipelines in <b>TeamCity</b> for a UE5 project targeting PC/Consoles such as Nintendo Switch. I would've loved to continue growing there but unfortunately it was shut down by our parent company, Sumo Digital. 
              Before that, I worked at <b>Powerex Corp</b> as a <b>.NET Developer</b> working on <b>.NET Framework</b> solutions, <b>React</b> Web Apps and <b>VBA</b> Add-ins. My other work has been 
              in <b>mobile game development</b> targetting both IOS/Android and in <b>data management/processing optimizations</b> in Java. I still work on personal projects, like designing game mechanics in Unity, exploring AR projects for mobile devices, or when I need a break from screens, I'm cross-stitching!
              <br/>
              <br/>
              ● Languages: C#, Python, Java, JavaScript/HTML/CSS, VBA, C/C++, PHP, SQL/NoSQL
              <br/>
              ●  Frameworks and Libraries: Nintendo SDK, React.js, D3, Angular.js, SignalR, Tizen OS, Node.js, Kinect SDK
              <br/>
              ● Tools: Perforce, Team Foundation Server, UE5, TeamCity, Jenkins, Git, Unity, Tableau, Trifecta Wrangler, Hololens, Arduino
              <br/>
              <br/>

              I have a <b>Bachelor's degree in Computer Science</b> with a concentration in Human Computer Interactions (UI/UX) from the University of Calgary
              <br/>
              I have a <b>Master's degree in Digital Media </b> from the Centre of Digital Media in Vancouver. This was an intense graduate program that provided me the opportunity and experience working with teams formed from a diverse set of backgrounds and skillsets.
              <br/>
              <br/>
              <br/>
          </p>
        </div>
      </div>
      
      <div className="parallax">
        <h2 className="animate__animated animate__fadeInDown " style={{color:"#003049", paddingTop:"2em"}}>
        Featured Personal Projects
        </h2>
      </div>
      <MainCarousel/>
    </div>
  );
}